import React from 'react';
import ReactDOM from 'react-dom';

export interface CarouselModalProps {
	className: string;
}

class CarouselModal extends React.Component<CarouselModalProps, {}> {
	private root: HTMLElement;
	private el: HTMLDivElement;

	constructor(props) {
		super(props);
		this.root =
			document.querySelector('#root') || document.querySelector('body');
		this.el = document.createElement('div');
		this.el.className = props.className;
	}

	componentDidMount() {
		this.root.appendChild(this.el);
	}

	componentWillUnmount() {
		this.root.removeChild(this.el);
	}

	render() {
		return ReactDOM.createPortal(this.props.children, this.el);
	}
}

export default CarouselModal;
