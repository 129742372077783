import { animated } from 'react-spring';
import styled from 'styled-components';
import { styledClient, styledTitle } from '../Layout/theme';

export const StyledWork = styled.section`
	display: flex;
	height: 100vh;
	padding: 10vh 0;
	position: relative;
	width: 100%;
	@media screen and (max-width: 1000px) {
		flex-direction: column;
		padding-top: 20vh;
	}
`;

export const Description = styled(animated.div)`
display: flex;
flex-direction: column;
height: 100%;
justify-content: center;
opacity: 1;
padding: 0 5vw;
width: 40vw;
/* transform: ${(props) =>
	props.enlarged ? `translateX(-30vw)` : `translateX(0vw)`}; */
@media screen and (max-width: 1000px) {
	width: 100%;
}
`;

export const Title = styled.h1`
	${styledTitle};
	font-size: 48px;
	margin-bottom: 0;

	@media screen and (max-width: 1200px) {
		font-size: 38px;
	}
`;

export const Subtitle = styled.h2`
	${styledClient};
	font-size: 24px;
	@media screen and (max-width: 1200px) {
		font-size: 20px;
	}
`;

export const Text = styled.p`
	color: ${(props) => props.theme.colorPassive};
	font-weight: 300;
	line-height: 1.3;
	white-space: pre-wrap;
`;

export const Footer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	@media screen and (max-width: 1000px) {
		display: none;
	}
`;

export const CarouselContainer = styled.div`
	align-items: center;
	display: flex;
	height: 100%;
	padding-right: 5vw;
	position: relative;
	overflow: hidden;
	width: 60vw;
	img {
		width: 100%;
	}
	@media screen and (max-width: 1000px) {
		width: 100%;
	}
`;

export const MobileContainer = styled.div`
	display: none;
	padding: ${(props) => props.theme.appGutter};

	@media screen and (max-width: 1000px) {
		display: block;
	}
`;
