import React, { useEffect, useRef, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring } from 'react-spring';

import CarouselModal from '../CarouselModal';
import {
	updateActiveItem,
	updateEnlargedItem,
	resetCarousel,
} from '../carousel.state';
import ModalItem from '../CarouselModal/modal';
import { StyledCarouselItem, CarouselImage } from './styled';
import { CAROUSEL_ITEM_MARGIN } from '../carousel.constants';

export default function CarouselItem({ id, item }) {
	const [dimensions, setDimensions] = useState({});
	const ref = useRef(null);
	const dispatch = useDispatch();
	const { activeItemId, enlargedItemId } = useSelector(
		(state) => state.carousel,
	);

	const enlarged = enlargedItemId === id;
	// const [ref, settings] = useModalSettings();

	// on init
	useEffect(() => {
		// on unmount
		return () => {
			dispatch(resetCarousel());
		};
	}, []);

	function onItemClick() {
		// avoid changing items if one is already enlarged
		if (enlargedItemId !== undefined) {
			return;
		}

		// if clicked item is active, enlarge
		if (activeItemId === id) {
			// TODO: store dimensions on init and on resize to speed up process
			const {
				top,
				left,
				right,
				width,
				height,
			} = ref.current.getBoundingClientRect();

			const { clientHeight, clientWidth } = document.documentElement;
			const maxHeight = clientHeight - CAROUSEL_ITEM_MARGIN;
			// screen width - right border * 2 - keeps image "centered"
			const maxWidth = clientWidth - (clientWidth - right) * 2;
			// max scales given max sizes
			const maxScaleY = 1 + (maxHeight - height) / height;
			const maxScaleX = 1 + (maxWidth - width) / width;
			// new potential widths
			const widthFromScaleY = width * maxScaleY;
			const widthFromScaleX = width * maxScaleX;
			// work out right scale
			const newScale = widthFromScaleY > maxWidth ? maxScaleX : maxScaleY;

			const newWidth =
				widthFromScaleY > maxWidth
					? widthFromScaleX - width
					: widthFromScaleY - width;

			setDimensions({ top, left, right, width, height, scaleY: newScale });
			dispatch(updateEnlargedItem(id, newWidth));
		} else {
			//if not, update active item
			dispatch(updateActiveItem(id));
		}
	}

	const itemSpring = useSpring({
		transform: activeItemId === id ? `scale(1)` : `scale(0.85)`,
		opacity: activeItemId === id ? 1 : 0.6,
	});

	return (
		<Fragment>
			<StyledCarouselItem
				onClick={onItemClick}
				ref={ref}
				style={{
					opacity: itemSpring.opacity,
					transform: itemSpring.transform,
				}}
			>
				<CarouselImage
					title={item.title}
					fluid={item.image.childImageSharp.fluid}
					loading="eager"
				/>
			</StyledCarouselItem>

			{enlarged && (
				<CarouselModal className="carousel__modal">
					<ModalItem item={item} settings={dimensions} />
				</CarouselModal>
			)}
		</Fragment>
	);
}
