import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSpring } from 'react-spring';

import { updateEnlargedItem, updateEnlargedOffset } from '../carousel.state';
import { StyledModalItem } from './styled';
import { CarouselImage } from '../CarouselItem/styled';

export default function Modal({ item, settings }) {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(true);

	const spring = useSpring({
		from: { transform: `scale(1)` },
		to: { transform: open ? `scale(${settings.scaleY})` : `scale(1)` },
		onRest,
	});

	// hide Modal once the animation ends
	function onRest() {
		if (!open) {
			dispatch(updateEnlargedItem(undefined));
		}
	}

	// reverse animation
	function close() {
		setOpen(false);
		dispatch(updateEnlargedOffset(0));
	}

	useEffect(() => {
		const handleKeyDown = (event) => {
			// only close if Esc pressed
			if (event.keyCode === 27 || event.keyCode === 'Escape') {
				close();
			}
			return;
		};

		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return (
		<StyledModalItem
			style={{ ...settings, transform: spring.transform }}
			onClick={close}
		>
			<CarouselImage fluid={item.image.childImageSharp.fluid} loading="eager" />
		</StyledModalItem>
	);
}
