import Img from 'gatsby-image';
import { animated } from 'react-spring';
import styled from 'styled-components';
import {
	CAROUSEL_ITEM_MARGIN,
	CAROUSEL_ITEM_WIDTH,
} from '../carousel.constants';

export const StyledCarouselItem = styled(animated.div)`
	backface-visibility: hidden;
	cursor: pointer;
	margin-right: ${CAROUSEL_ITEM_MARGIN}px;
	max-height: 100%;
	width: ${CAROUSEL_ITEM_WIDTH}vw;
`;

export const CarouselImage = styled(Img)`
	background-color: ${(props) => props.theme.colorPrimary};
	width: 100%;
`;
