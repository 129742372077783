import { animated } from 'react-spring';
import styled from 'styled-components';

export const StyledCarousel = styled(animated.div)`
	align-items: center;
	backface-visibility: hidden;
	display: flex;
	img {
		width: 100%;
	}

	@media screen and (max-width: 1000px) {
		display: none;
	}
`;
