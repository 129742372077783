import styled, { keyframes } from 'styled-components';

export const StyledCarouselControls = styled.div`
	align-items: center;
	display: flex;
`;

export const CarouselArrow = styled.button`
	background: transparent;
	border: none;
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	display: inherit;
	fill: ${(props) =>
		props.disabled ? props.theme.colorPassive : props.theme.colorAccent};
	padding: 0 5px;
	transition: fill .2s ease;
`;

const blink = keyframes`
	0%,
	49% {
		opacity: 1;
	}
	50%,
	100% {
		opacity: 0;
	}
`;

export const CarouselIndex = styled.span`
	cursor: pointer;
	font-size: 12px;
	margin: 0 5px;
	position: relative;
	&:after {
		animation: ${blink} 1s infinite;
		background: ${(props) => props.theme.colorAccent};
		content: '';
		display: ${(props) => (props.active ? `block` : `none`)};
		position: absolute;
		mix-blend-mode: lighten;
		bottom: 0;
		right: 0;
		height: 100%;
		width: 100%;
		transform: scale(1.2);
	}
`;
