import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { animated, useSpring, useTrail } from 'react-spring';

import {
	StyledWork,
	CarouselContainer,
	Description,
	Title,
	Subtitle,
	Text,
	Footer,
	MobileContainer,
} from './styled';
import Button from '../Button/button';
import CarouselControls from '../Carousel/CarouselControls';
import LinkButton from '../LinkButton/LinkButton';
import Carousel from '../Carousel/Carousel';

export default function Work({ data }) {
	const { client, description, link, images, title } = data;
	const state = useSelector((state) => state.carousel);
	const enlarged = state.enlargedItemId !== undefined;

	const getMarkup = useCallback(
		(content: string) => {
			return { __html: content };
		},
		[description],
	);

	const spring = useSpring({
		transform: enlarged
			? `translateX(-${state.enlargedOffset}px)`
			: `translateX(0px)`,
	});

	const items = [
		<Title>{title}</Title>,
		<Subtitle>{client}</Subtitle>,
		<Text dangerouslySetInnerHTML={getMarkup(description)} />,
		<Footer>
			<CarouselControls items={images} />
			{link && <LinkButton link={link}>See it live</LinkButton>}
		</Footer>,
	];

	const trail = useTrail(items.length, {
		opacity: 1,
		x: 0,
		from: { opacity: 0, x: -10 },
	});

	return (
		<StyledWork>
			<Description style={{ transform: spring.transform }}>
				{trail.map(({ x, opacity }, index) => (
					<animated.div
						key={index}
						style={{
							transform: x.interpolate((x) => `translate3d(${x}%, 0, 0)`),
							opacity: opacity.interpolate((opacity) => opacity),
						}}
					>
						{items[index]}
					</animated.div>
				))}
			</Description>

			<CarouselContainer>
				<Carousel items={images} />
			</CarouselContainer>

			<MobileContainer>
				<Button>See images</Button>
			</MobileContainer>
		</StyledWork>
	);
}
