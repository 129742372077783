import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring } from 'react-spring';

import { StyledCarousel } from './styled';
import CarouselItem from './CarouselItem';
import {
	CAROUSEL_ITEM_MARGIN,
	CAROUSEL_ITEM_WIDTH,
} from './carousel.constants';
import { ICarouselItem } from './carousel.interface';
import { updateActiveItem, updateEnlargedItem } from './carousel.state';

export default function Carousel({ items }: { items: ICarouselItem[] }) {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.carousel);

	useEffect(() => {
		function handleResize() {
			dispatch(updateEnlargedItem(undefined));
		}

		function handleKeyDown(event) {
			if (state.enlargedItemId !== undefined) {
				return;
			}

			if (event.keyCode === 37 || event.keyCode === 'ArrowLeft') {
				if (state.activeItemId > 0)
					dispatch(updateActiveItem(state.activeItemId - 1));
			}
			if (event.keyCode === 39 || event.keyCode === 'ArrowRight') {
				if (state.activeItemId < items.length - 1)
					dispatch(updateActiveItem(state.activeItemId + 1));
			}
		}

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('resize', handleResize);
		};
	});

	const carouselX = CAROUSEL_ITEM_WIDTH * state.activeItemId;
	const marginX = CAROUSEL_ITEM_MARGIN * state.activeItemId;

	const carouselSpring = useSpring({
		transform: `translateX(calc(-${carouselX}vw - ${marginX}px))`,
	});

	return (
		<StyledCarousel style={{ transform: carouselSpring.transform }}>
			{items
				.sort((a, b) => a.order - b.order)
				.map((item, i) => <CarouselItem key={item.order} item={item} id={i} />)}
		</StyledCarousel>
	);
}
