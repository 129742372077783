import React, { ReactChild, memo } from 'react';

import './link-button.scss';

interface IButtonProps {
	children: ReactChild;
	link: string;
	tabIndex?: number;
}
export default memo((props: IButtonProps) => {
	const { children, link, tabIndex = 0 } = props;

	return (
		<a
			className="link-button"
			rel="noreferrer"
			href={link}
			tabIndex={tabIndex}
			target="_blank"
			title="See live project in a new tab"
		>
			{children}
		</a>
	);
});
