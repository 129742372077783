import React, { ReactChild } from 'react';

import './button.scss';

interface IButtonProps {
	children: ReactChild;
	disabled?: boolean;
	onClick?: () => void;
}

export default (props: IButtonProps) => {
	const { disabled, onClick, children } = props;

	return (
		<button className="button" disabled={disabled} onClick={onClick}>
			{children}
		</button>
	);
};
