import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO/SEO';
import Work from '../components/Work';

export default class Template extends React.Component<any, any> {
	render() {
		const { data } = this.props;

		const { markdownRemark, site } = data;
		const { siteMetadata } = site;
		const {
			client,
			description,
			link,
			images,
			title,
		} = markdownRemark.frontmatter;

		return (
			<Fragment>
				<SEO imageProvided>
					<title>
						{siteMetadata.title} - {title}
					</title>
					<meta name="description" content={siteMetadata.description} />
					<meta property="og:title" content={siteMetadata.title} />
					<meta property="og:description" content={description} />
					<meta
						property="og:image"
						content={`https://wonkylines.io${images[0].image.childImageSharp
							.fluid.src}`}
					/>
				</SEO>

				<Work data={markdownRemark.frontmatter} />
			</Fragment>
		);
	}
}
export const pageQuery = graphql`
	query ProjectQuery($slug: String!) {
		site {
			...SiteMetadata
		}
		markdownRemark(frontmatter: { path: { eq: $slug } }) {
			frontmatter {
				client
				description
				link
				title
				images {
					image {
						childImageSharp {
							fluid(maxWidth: 1400, quality: 85) {
								src
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					title
					order
				}
			}
		}
	}
`;
