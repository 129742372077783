import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CarouselArrow, CarouselIndex, StyledCarouselControls } from './styled';
import ArrowLeft from './icons/arrow-left.svg';
import ArrowRight from './icons/arrow-right.svg';
import { updateActiveItem } from '../carousel.state';

export default function CarouselControls({ items }: { items: any[] }) {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.carousel);
	const isFirstActive = state.activeItemId === 0;
	const isLastActive = state.activeItemId === items.length - 1;

	const setItemActive = (i) => dispatch(updateActiveItem(i));

	return (
		<StyledCarouselControls>
			<CarouselArrow
				aria-label="Previous"
				disabled={isFirstActive}
				onClick={() => setItemActive(state.activeItemId - 1)}
			>
				<ArrowLeft />
			</CarouselArrow>

			{items.map((item, i) => (
				<CarouselIndex
					active={i === state.activeItemId}
					key={i}
					onClick={() => setItemActive(i)}
				>
					{i}
				</CarouselIndex>
			))}

			<CarouselArrow
				aria-label="Next"
				disabled={isLastActive}
				onClick={() => setItemActive(state.activeItemId + 1)}
			>
				<ArrowRight />
			</CarouselArrow>
		</StyledCarouselControls>
	);
}
